.App {
    text-align: center;
    font-family: "belarius-poster", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.App-link {
    color: #61dafb;
}
#title {
    font-family: belarius-poster, serif;
}

.navigation {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    width: 100%;
    padding: 0.5rem 0;
    background-color: transparent;
    color: black;
    /*box-shadow: 0 2px 2px 2px rgba(9, 9, 9, 0.23);*/
}

.brand-name {
    text-decoration: none;
    color: black;
    font-size: 1.3rem;
    margin-left: 1rem;
}

.navigation-menu {
    margin-right: auto;
    margin-top: 1rem;
    /*mix-blend-mode: difference;*/
    /*filter: invert(1);*/
}

.navigation-menu ul {
    display: flex;
    padding: 0;
}

.navigation-menu li {
    list-style-type: none;
    margin: 0 1rem;
}

.navigation-menu li a {
    text-decoration: none;
    color: var(--text);
    font-size: 1.2rem;
    display: block;
    width: 100%;
}

.hamburger {
    border: 0;
    height: 40px;
    width: 40px;
    padding: 0.5rem;
    border-radius: 50%;
    background-color: transparent;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translateY(-50%);
    display: none;
}

.hamburger:hover {
    background-color: transparent;
}

@media screen and (max-width: 550px) {
    .hamburger {
        display: block;
    }

    .navigation-menu ul {
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        height: calc(100vh - 60px);
        background-color: rgba(0, 0, 0, 0.7);
        /*border-top: 1px solid black;*/
        display: none;
    }

    .navigation-menu {
        mix-blend-mode: unset;
        background-color: rgba(0, 0, 0, 0.7);

    }

    .navigation-menu li {
        text-align: center;
        margin: 0;
    }

    .navigation-menu li a {
        color: white;
        width: 100%;
        padding: 1.5rem 0;
    }

    .navigation-menu li:hover {
        background-color: #eee;
    }

    .navigation-menu.expanded ul {
        display: block;
        z-index: 1000;
        /*transition: background-color 0.2s ease-in-out;*/
    }
}
